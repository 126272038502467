import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from '../firebase.js';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const auth = getAuth();

    const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const userDoc = await getDoc(doc(db, 'users', user.uid));
            if(userDoc.exists()) {
                const userData = userDoc.data();
                if(userData.isAdmin) {
                    navigate('/dashboard');
                } else {
                    setError('You are not authorized to access the admin dashboard.');
                }
            } else {
                setError('No user record found');
            }
        } catch(error) {
            console.error('Error during sign-in:', error);
            setError('Failed to sign in. Please check your credentials.');
        }

        setLoading(false);
    };

    return (
        <div className="flex items-center justify-center h-screen p-4 bg-[#F3F4F7]">
            <div className="shadow-[rgba(149,157,165,0.2)_0px_8px_24px] border border-solid border-white w-[528px] p-6 rounded-xl @apply bg-[linear-gradient(to_top,#fefeff,#fefeff,#fefeff,#fefeff,#fefeff,#fdfdff,#fbfbff,#fafaff,#f7f7ff,#f5f4ff,#f3f1fe,#f1eefe)]">
                <h2 className="text-2xl font-bold text-[#141414] text-center mb-2">Welcome!</h2>
                <p className="text-medium font-normal text-neutral-500 text-center mb-4">Enter your credentials to access the dashboard.</p>
                <hr className="mb-4"/>
                {error && <p className="text-red-600 mb-4">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col mb-4">
                        <label className="text-left text-gray-700 mb-1">Email address</label>
                        <input 
                            className="border border-solid border-gray-200 rounded-lg p-2"
                            type="email" 
                            value={email} 
                            placeholder="Enter your email" 
                            required
                            onChange={(e) => setEmail(e.target.value)} 
                        />
                    </div>
                    <div className="flex flex-col mb-6">
                        <label className="text-left text-gray-700 mb-1">Password</label>
                        <input 
                            className="border border-solid border-gray-200 rounded-lg p-2"
                            type="password"
                            value={password}
                            placeholder="Enter your password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <hr className="mb-4"/>
                    <button type="submit" disabled={loading} className="rounded-xl font-bold border border-solid border-[#5238b9] text-white w-full p-3 bg-[linear-gradient(to_top,#6d4def,#7e62ee,#8d76ec,#9c89e9,#aa9ce6)] hover:bg-[linear-gradient(to_top,#5a3ed8,#6b50db,#7a63d9,#8977d7,#978ad3)]">Sign In</button>
                </form>
            </div>
        </div>
    )
}
export default Login;