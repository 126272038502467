import React from "react";

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) =>{
    if (!isOpen) return null;
    return (
        <div className="z-50 fixed w-full h-full bg-[rgba(0,0,0,0.5)] flex justify-center items-center left-0 top-0">
            <div className="bg-[white] text-center max-w-[400px] p-8 rounded-lg">
                <div className="flex justify-center">
                    <svg className="bg-[rgb(255,0,0,0.1)] p-2 rounded-[50%]" width="48px" height="48px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12" cy="17" r="1" fill="red"/>
                    <path d="M12 10L12 14" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M3.44722 18.1056L10.2111 4.57771C10.9482 3.10361 13.0518 3.10362 13.7889 4.57771L20.5528 18.1056C21.2177 19.4354 20.2507 21 18.7639 21H5.23607C3.7493 21 2.78231 19.4354 3.44722 18.1056Z" stroke="red" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
                <h2 className="text-[22px] px-0 py-2"><strong>Are you sure?</strong></h2>
                <p className="text-base text-[#828793] px-0 py-2">This action cannot be undone. All values associated with this field will be lost.</p>
                <button className="w-full text-[white] bg-rose-600 text-base mt-3 mb-4 px-0 py-3 rounded-lg border-[none] hover:bg-[#ca173e]" onClick={onConfirm}>Delete</button>
                <button className="w-full text-[black] bg-white border text-base px-0 py-3 rounded-lg border-solid border-[rgba(0,0,0,0.4)] hover:bg-[#f5f5f5]" onClick={onClose}>Cancel</button>
            </div>
        </div>
    )
};
export default ConfirmationModal;