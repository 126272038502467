import React, { useEffect, useState } from "react";

const UpdateModal = ({ isOpen, onClose, onConfirm, data }) => {
    
    const [formData, setFormData] = useState({
        title: "",
        post_url: "",
        description: "",
        image_url: "",
        platforms: []
    });

    useEffect(() => {
        if (data) {
            setFormData({
                ...data,
                platforms: data.platforms || []
            });
        }
    }, [data]);

    if (!isOpen) return null;

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setFormData((prevData) => ({
                ...prevData,
                platforms: checked
                    ? [...(prevData.platforms || []), name]
                    : prevData.platforms.filter((platform) => platform !== name),
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onConfirm(formData);
    }

    const handleButtonClick = (platform) => {
        setFormData((prevState) => {
          const isSelected = prevState.platforms.includes(platform);
          const newPlatforms = isSelected
            ? prevState.platforms.filter((p) => p !== platform)
            : [...prevState.platforms, platform];
      
          return { ...prevState, platforms: newPlatforms };
        });
      };

    return (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-xl p-6 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 max-h-full">
                <div className="flex justify-between items-center">
                    <button className="text-3xl" onClick={onClose}>&times;</button>
                    <div className="text-2xl"><strong>Update Post</strong></div>
                    <div></div>
                </div>
                    <form className="mt-6">
                         <div className="mb-6">
                            <label className="block font-medium text-left mb-[5px] font-sans text-left" htmlFor="title">Title</label>
                            <input 
                                className="bg-[#F1F3F4] w-full rounded-md py-3 px-4"
                                required
                                type="text"
                                id="title"
                                name="title"
                                placeholder="Enter Title"
                                value={formData.title}
                                onChange={handleChange}
                            />
                            </div>
                        <div className="mb-6">
                            <label className="block font-medium text-left mb-[5px] font-sans text-left" htmlFor="post_url">Post Link</label>
                            <input 
                                className="bg-[#F1F3F4] w-full rounded-md py-3 px-4"
                                required
                                type="text"
                                id="post_url"
                                name="post_url"
                                placeholder="Post Link"
                                value={formData.post_url}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="block font-medium text-left mb-[5px] font-sans text-left" htmlFor="post_url">Description</label>
                            <textarea 
                                required
                                className="bg-[#F1F3F4] w-full rounded-md py-3 px-4 resize-none"
                                id="description"
                                name="description"
                                placeholder="Description"
                                rows={5}
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-6">
                            <label className="block font-medium text-left mb-[5px] font-sans text-left" htmlFor="image_url">Image URL</label>
                            <input 
                                className="bg-[#F1F3F4] w-full rounded-md py-3 px-4"
                                required
                                type="text"
                                id="image_url"
                                name="image_url"
                                placeholder="Image URL"
                                value={formData.image_url}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-6">
                            <span className="block text-left font-bold text-xl mb-2">Share To</span>
                            <div className="flex flex-wrap gap-2">
                                {['Facebook', 'X', 'Instagram', 'Threads', 'TikTok', 'WhatsApp', 'LinkedIn'].map((platform) => (
                                <button
                                    key={platform}
                                    type="button"
                                    className={`px-4 py-2 rounded-full ${
                                    formData.platforms.includes(platform.toLowerCase()) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                    }`}
                                    onClick={() => handleButtonClick(platform.toLowerCase())}
                                >
                                    {platform}
                                </button>
                                ))}
                            </div>
                        </div>
                        <button className="w-full bg-sky-500 text-white py-3 rounded-lg hover:bg-sky-600 mb-4" onClick={handleSubmit}>Update</button>
                    </form>
                
                <button className="w-full text-[black] bg-white border text-base px-0 py-3 rounded-lg border-solid border-[rgba(0,0,0,0.4)] hover:bg-[#f5f5f5]" onClick={onClose}>Cancel</button>
            </div>
        </div>
    )
}
export default UpdateModal;