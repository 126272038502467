import React, { useState, useEffect } from "react";
import { db } from '../firebase';
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const CreatePostModal = ({ isOpen, onClose, onPostCreated }) => {

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        post_url: '',
        image_url: '',
        platforms: []
    });
    const [error, setError] = useState(null);

    const handleButtonClick = (platform) => {
        setFormData((prevState) => {
          const isSelected = prevState.platforms.includes(platform);
          const newPlatforms = isSelected
            ? prevState.platforms.filter((p) => p !== platform)
            : [...prevState.platforms, platform];
      
          return { ...prevState, platforms: newPlatforms };
        });
      };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            await addDoc(collection(db, "media"), {...formData, timestamp: serverTimestamp(), clicks: 0 });
            setFormData({ title: '', description: '', post_url: '', image_url: '', platforms: [] });
            onPostCreated();
        } catch(error) {
            setError("Error adding data. Please try again.");
            console.error("Error adding data: ", error);
        }
    };

    // clear the form on close
    useEffect(() => {
        if(!isOpen) setFormData({ title: '', description: '', post_url: '', image_url: '', platforms: [] });
    }, [isOpen])

    if (!isOpen) return null;

    return (
        <div className="fixed z-50 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-xl p-6 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/2 max-h-full">
                <div className="flex justify-between items-center">
                    <button className="text-3xl" onClick={onClose}>&times;</button>
                    <div className="text-2xl"><strong>Add a new post</strong></div>
                    <div></div>
                </div>
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <input 
                            type="text"
                            id="title"
                            name="title"
                            required
                            className="bg-[#F1F3F4] w-full rounded-md py-3 px-4"
                            placeholder="Title"
                            value={formData.title}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-6">
                        <textarea 
                            rows={5}
                            id="description"
                            name="description"
                            required
                            placeholder="Description"
                            className="bg-[#F1F3F4] w-full rounded-md py-3 px-4 resize-none"    
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-6">
                        <input 
                            type="text"
                            id="post_url"
                            name="post_url"
                            required
                            placeholder="Post URL"
                            className="bg-[#F1F3F4] w-full rounded-md py-3 px-4"
                            value={formData.post_url}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-6">
                        <input 
                            type="text"
                            id="image_url"
                            name="image_url"
                            required
                            placeholder="Image URL"
                            className="bg-[#F1F3F4] w-full rounded-md py-3 px-4"
                            value={formData.image_url}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mb-6">
                        <span className="block text-left font-bold text-xl mb-2">Share To</span>
                        <div className="flex flex-wrap gap-2">
                            {['Facebook', 'X', 'Instagram', 'Threads', 'TikTok', 'WhatsApp', 'LinkedIn'].map((platform) => (
                            <button
                                key={platform}
                                type="button"
                                className={`px-4 py-2 rounded-full ${
                                formData.platforms.includes(platform.toLowerCase()) ? 'bg-blue-500 text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                                }`}
                                onClick={() => handleButtonClick(platform.toLowerCase())}
                            >
                                {platform}
                            </button>
                            ))}
                        </div>
                    </div>
                    <button type="submit" className="w-full bg-sky-500 text-white py-3 rounded-lg hover:bg-sky-600" >Create</button>
                </form>
            </div>
        </div>
    );
};
export default CreatePostModal;