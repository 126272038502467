import React, { useState } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';


const Buttons = ({ activePlatform, link, image_link, copyText }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const [copyOverlay, setCopyOverlay] = useState(false);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);
    const [isMobileModalVisible, setIsMobileModalVisible] = useState(false);

    if (!activePlatform) return null;

    const platformClasses = {
        instagram: 'instagram-buttons',
        facebook: 'facebook-buttons',
        x: 'x-buttons',
        linkedin: 'linkedin-buttons',
        threads: 'threads-buttons',
        whatsapp: 'whatsapp-buttons',
        tiktok: 'tiktok-buttons'
    };

    const shareButtonText = {
        instagram: 'Distribuie pe Instagram',
        facebook: 'Distribuie pe Facebook',
        x: 'Distribuie pe X',
        linkedin: 'Distribuie pe Linkedin',
        threads: 'Distribuie pe Threads',
        whatsapp: 'Distribuie pe Whatsapp',
        tiktok: 'Distribuie pe TikTok'
    };

    const handleCopyText = () => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(copyText).then(() => {
                setIsModalVisible(true);
                setIsFadingOut(false);

                setTimeout(() => {
                    setIsFadingOut(true);
                    setTimeout(() => {
                        setIsModalVisible(false);
                    }, 250);
                }, 5000);
            }).catch((error) => {
                console.error('Error copying text: ', error);
            });
        } else {
            console.error('Clipboard API not available');
        }
    };

    const handleDownloadImageAndCopyText = async () => {
        
        handleCopyText();
        
        try {  
            const extension = image_link.split('.').pop().split('?')[0];
            const response = await fetch(image_link);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `image.${extension}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
        catch(error) {
            console.error('Error downloading the image ', error);
        }
    };

    const displayCopyDownloadOverlay = () => {
        handleDownloadImageAndCopyText();
        setCopyOverlay(true);
        setIsOverlayOpen(true);
    };

    const closeOverlay = () => {
        setCopyOverlay(false);
        setIsOverlayOpen(false);
    };

    const truncateText = (text, charLimit) => {
        if (text.length <= charLimit) return text;
        return text.substring(0, charLimit) + "...";
    };

    const isMobile = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    const handleShare = () => {
       
            if (isMobile()) {
                setIsMobileModalVisible(true);
            } else {
                performShare();
            }
    };

    const handleMobileShare = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Share',
                text: copyText,
                url: link,

            }).catch((error) => console.error('Error sharing:', error));
        } else {
            performShare();
        }
    };


    const performShare = () => {
        let shareUrl = '';
        let textToShare = copyText;
        if (activePlatform === 'x') {
            textToShare = truncateText(copyText, 100);
        }
        if (activePlatform === 'facebook' || activePlatform === 'linkedin' || activePlatform === 'tiktok') {
            handleCopyText(); // Ensure text is copied to clipboard
        }
        switch(activePlatform) {
            case 'facebook':
                    if(isMobile()){      
                            shareUrl="fb://publish"
                    }else{
                        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`;
                    }
                break;
            case 'instagram':
                displayCopyDownloadOverlay();
                break;
            case 'x':
                shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=${encodeURIComponent(textToShare)}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(link)}`;
                break;
            case 'threads':
                shareUrl = `https://www.threads.net/intent/post?text=${encodeURIComponent(copyText)}`;
                break;
            case 'whatsapp':
                shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(copyText)}`;
                break;
            case 'tiktok':
                displayCopyDownloadOverlay();
                break;
            default:
                return;
        }
        if(activePlatform !== 'instagram' && activePlatform !== 'tiktok')
            window.open(shareUrl, '_blank');
    };

    const handleDefaultUrl = () => {
        let shareUrl = '';

        switch(activePlatform) {
            case 'instagram':
                shareUrl = 'https://www.instagram.com/';
                break;
            case 'tiktok':
                shareUrl = `https://www.tiktok.com/upload?url=${encodeURIComponent(link)}`;
                break;
            default:
                return;
        }
        window.open(shareUrl, '_blank');
    };

    return (
        <div className='flex flex-col w-full mt-3'>
            <div className="flex flex-row justify-center items-center">
                <CopyToClipboard text={copyText}>
                    <button
                        className={`flex justify-center items-center h-12 rounded-l-lg border-none focus:outline-none ${
                            activePlatform === 'instagram' ? 'text-white bg-gradient-to-r from-[#E9A949] via-red-500 to-red-500 w-full' :
                            activePlatform === 'facebook' ? 'text-white bg-[#3B5998] w-full' :
                            activePlatform === 'x' ? 'text-white bg-black w-full' :
                            activePlatform === 'linkedin' ? 'text-white bg-blue-700 w-full' :
                            activePlatform === 'threads' ? 'text-white bg-black w-full' :
                            activePlatform === 'whatsapp' ? 'text-black bg-[lightgreen] w-full' :
                            activePlatform === 'tiktok' ? 'text-white bg-black w-full' : ''
                        }`}
                        onClick={handleShare}
                    >
                        {shareButtonText[activePlatform]} 
                    </button>
                </CopyToClipboard>
                <button 
                    className={`flex justify-center items-center h-12 rounded-r-lg border-none focus:outline-none ${
                        activePlatform === 'instagram' ? 'ml-[1px] text-white bg-gradient-to-r from-red-500 to-[#A74883] w-14' :
                        activePlatform === 'facebook' ? 'ml-[1px] text-white bg-[#3B5998] w-14' :
                        activePlatform === 'x' ? 'ml-[1px] text-white bg-black w-14' :
                        activePlatform === 'linkedin' ? 'ml-[1px] text-white bg-blue-700 w-14' :
                        activePlatform === 'threads' ? 'ml-[1px] text-white bg-black w-14' :
                        activePlatform === 'whatsapp' ? 'ml-[1px] ext-black bg-[lightgreen] w-14' :
                        activePlatform === 'tiktok' ? 'ml-[1px] text-white bg-black w-14' : ''
                    }`}
                    onClick={handleDownloadImageAndCopyText}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 16V17C4 17.7956 4.31607 18.5587 4.87868 19.1213C5.44129 19.6839 6.20435 20 7 20H17C17.7956 20 18.5587 19.6839 19.1213 19.1213C19.6839 18.5587 20 17.7956 20 17V16M16 12L12 16M12 16L8 12M12 16V4" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                </button>
                {isModalVisible && (
                    <div className={`fixed top-14 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#16FF00] p-2.5 rounded z-50 bg-[#B3EF69] transition-opacity duration-500 ${isFadingOut ? 'opacity-0' : 'opacity-100'}`}>
                        Text copied
                    </div>
                )}
                {copyOverlay && (
                    <div className="fixed w-full h-full bg-[rgb(0_0_0_/_80%)] z-[1000] flex flex-col items-center text-[white] justify-center text-[32px] gap-6 left-0 top-0" onClick={closeOverlay}>
                        <span>Text Copiat</span>
                        <span>1. Selecteaza fisierul descarcat</span>
                        <span>2. Lipeste Textul</span>
                        <button className="z-[1001] border text-[whitesmoke] font-[bold] text-base bg-[#141414] px-5 py-4 rounded-[32px] border-solid border-[white]" onClick={handleDefaultUrl}>
                            {shareButtonText[activePlatform]}
                        </button>
                    </div>
                )}
                {isMobileModalVisible  &&  (
                    <div className="fixed w-full h-full bg-[rgba(0,0,0,0.8)] flex justify-center items-center z-[1000] left-0 top-0">
                        <div className="bg-white text-center w-4/5 max-w-[400px] shadow-[0_2px_10px_rgba(0,0,0,0.1)] p-5 rounded-lg">
                            <span className="block text-base text-[#333] mb-[15px]">Urmează acești pași pentru a crea o postare:</span>
                            <span className="block text-base text-[#333] mb-[15px]">1. Apasă pe butonul de mai jos pentru a deschide meniul de distribuire de pe telefonul tău.</span>
                            <span className="block text-base text-[#333] mb-[15px]">2. Selectează aplicația {activePlatform.charAt(0).toUpperCase() + activePlatform.slice(1)}</span>
                            <span className="block text-base text-[#333] mb-[15px]">3. Dacă lipsește textul, lipește-l sau dă-i paste! Textul e deja copiat.</span>
                            
                            <button className="bg-[#007bff] text-white cursor-pointer text-base transition-[background] duration-[0.3s] ease-[ease] m-[5px] px-5 py-2.5 rounded-[5px] border-[none]" onClick={handleMobileShare}>Share</button>
                            <button className="bg-[#007bff] text-white cursor-pointer text-base transition-[background] duration-[0.3s] ease-[ease] m-[5px] px-5 py-2.5 rounded-[5px] border-[none]" onClick={() => setIsMobileModalVisible(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Buttons;
